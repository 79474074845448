@import 'assets/scss/link';




*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a{
  text-decoration: none;
  color:$main-color;
}

li{
  list-style: none;
}

html,body{
 @include square(100%);
  &::-webkit-scrollbar-thumb{
    background-color: $white-700;
  }
  &::-webkit-scrollbar{
    width: 8px;
  }
  &::-webkit-scrollbar-track{
    background-color: transparent;
  }
}

app-home{
  background-color: $white;
}

app-hero, {
  width: 100%;
}

app-tab-side-bar-ui{
  flex: 1;
  height: 100%;
  max-width: 300px;
}

h1,h2,h3,h4,h5,h6{

   em {
     background-color: $highlight;
     border-radius: 2px;
     padding: 0.05em;
     font-style: normal;
   }

}

.imgWrapper{
  @include square(100%);
  border-radius: 100%;
  overflow: hidden;
  img{
    @include square(100%);
    object-fit: cover;
  }
}

.nav-links{
  height: 100%;
  ul{
    @include flex(end);
    height: 100%;
    gap: $main-space;
    .nav-links_items{
      @include flex(center);
      align-items: stretch;
      position: relative;
      height: 100%;
      &.disabled{
        pointer-events: none;
        .nav-link_btn{
          color: #AAACAE;
        }
      }
      &.outerLink{
        align-items: center;
        .nav-link_btn{
          color: $white;
          padding: .25rem .5rem;
          border-radius: 16px;
          border: 2px solid $primary;
          background-color: $primary;
          transition: all .5s ease-in-out;
        }
        &:hover{
          a{
            background-color: $white;
            color: $primary;
            transition: all .5s ease-in-out;
          }

        }
      }
      .nav-link_btn{
        @include font($main-font, $main-color, clamp(14px, $fm_size_desk-16, 16px), 400);
        transition: all .3s ease-in-out;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        cursor: pointer;
        border:none;
        background-color: transparent;
        outline: none;
        &::after{
          @include size(4px, 0%);
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          transition: width .2s ease-in-out;
          border-radius: 4px;
          background-color: $primary;
          translate: 0 50%;
        }
        &.active{
          color: $primary;
          &::after{
            width: 100%;
          }
        }
        &:hover{
          span{
            background-color: $highlight;
            transition: background-color .3s ease-in-out;
          }
        }
        span{
          padding: 0.5em;
          border-radius: 8px;
          transition: background-color .3s ease-in-out;
        }
      }
    }
  }
}
.nav-logo{
  @include flex(start);
  cursor: pointer;
  gap: .25rem;
  img{ @include size(40px, 50px) }
  .logo_name{ @include font($main-font, $main-color, 16px,500) }
}
.options-list_ulEL{
  @include flex(start,column);
  gap: $item-space-between;
  li{
    @include font($main-font, $main-color, clamp(14px, $fm_size_desk-16, 16px), 400);
    @include hover_highlightBtn();
    text-transform: capitalize;
    width: 100%;
    text-align: start;
    padding: calc($main-space / 2);
    transition: all .3s ease-in-out;
    cursor: pointer;
  }
}

.messagePg{
  @include flex(center, column);
  max-width: $mx-content-width;
  gap: $main-space;
  padding: $main-space;
  @include mainBox($white);
  h4{
    @include font($main-font, $main-color, clamp(20px, $fm_size_desk-32, 32px), 700);
    text-align: center;
  }
  p{
    @include font($main-font, $main-color, clamp(16px, $fm_size_desk-20, 20px), 500);
    text-align: center;
    a{
      color: $primary;
      text-decoration: underline;
    }
  }
}

.highlight{
  background-color: $highlight;
  border-radius: 2px;
  padding: 0.05em;
  font-style: normal;
}

.notFound{
  @include font($main-font, $main-color, clamp(14px, $fm_size_desk-16, 16px),600);
}

.content{
  @include flex(center, column);
  max-width: $mx-content-width;
  gap: 2em;
  .content_box{
    width: 40%;
    min-width: 800px;
    @include flex(start, column);
    align-items: flex-start;
    gap: 1em;
    .content_title{
      @include font($main-font, $main-color, $fm_size_desk-32,600);
    }
    .content_text{
      @include font($main-font, $main-color, $fm_size_desk-24,400);
      a{
        color:$main-color;
        text-decoration: underline;
        &:hover{
          color:$primary;
        }
      }
    }
    .sub_content{
      @include font($main-font, $main-color, $fm_size_desk-20,400);
    }
  }
}

.docs-content{
  @include square(100%);
  font-family: 'Share Tech Mono', monospace;
  max-width: 1200px;
  padding: 2rem 1rem;
  @include flex(start,column);
  align-items: flex-start;
  gap: 2em;
  h2{ font-size: clamp(1.5rem, 3vw, 3rem);}
  h3{font-size: clamp(.9rem, 2vw, 1.5rem);}
  h4 {font-size: clamp(1rem, 2vw, 1.5rem);}
  p{
    font-size: clamp(1rem, 2vw, 1.5rem);
    padding: 0 .25em;
    a{
      color:$primary;
      text-decoration: underline;
    }
  }
  ul{
    padding: 0 0 0 5em;
    li{
      list-style: disc;
      font-size: clamp(1rem, 2vw, 1.5rem);
    }
  }
  .tb-wrapper{
    width: 100%;
    max-width: 1200px;
    overflow-x: auto;
    table{
      width: 1160px;
      border-collapse: collapse;
      border: 2px solid $main-color;
      tr{
        border: 2px solid $main-color;
        th,td{
          border: 2px solid $main-color;
          padding: .5em;
          font-size: clamp(1rem, 2vw, 1.5rem);
        }
        td:not(:nth-child(2)){
          text-align: center;
        }
      }
    }
  }
  .main-txt{
    @include flex(start,column);
    align-items: flex-start;
    gap: 2em;
    width: 100%;

  }
}

.main-content-wrapper{
  @include main-content-wrapper();
  align-items: flex-start;
  gap: $main-space;

}

.blurContent{
  filter: blur(5px);
  pointer-events: none;
  transition: all .2s ease-in-out;
}

.exp-create_tab-wrapper{
  @include flex(start, column);
  gap: $inbox-space;
  &.scrollable_tab-wrapper{
    height: 100%;
    .exp-create_tab {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-rows: auto 1fr;
      height: 100%;
      overflow: hidden;
      .exp-create_tab-main {
        @include scroll-bar($scroll-bar-color, 4px);
        overflow: auto;
        height: 100%;
        position: relative;
        .list-wrapper{
          @include size( max-content, 100%);
          .list-container{
            @include flex(start);
            align-items: stretch;
            flex-wrap: wrap;
            gap: calc($inbox-space / 2);
          }
        }
      }
    }
  }
  .exp-create_tab{
    @include mainBox(#ffffff);
    @include flex(start, column);
    gap: $inbox-space;
    width: 100%;
    .exp-create_tab-main{
      width: 100%;
    }
  }
}

@media screen and (min-width: 1600px){
  .content{
    .content_box{
      .content_title{
        font-size: 32px;
      }
      .content_text{
        font-size: 24px;
      }
      .sub_content{
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 1020px){
  .content{
    max-width: 100%;
    width: 100%;
    .content_box{
      min-width: 100%;
      .content_title{
        font-size: 20px;
      }
      .content_text{
        font-size: 16px;
      }
      .sub_content{
        font-size: 14px;
      }
    }
  }
  .nav-links{
    @include flex(start);
    @include size(100dvh, clamp(275px, 30vw, 300px));
    background-color: $main-color;
    position: fixed;
    inset: 0 0 auto auto;
    z-index: 999;
    padding: $main-space;
    translate: 100% 0;
    transition: translate .2s ease-in-out;
    &.active{
      translate: 0 0;
      transition: translate .2s ease-in-out;
    }
    ul{
      @include flex(start, column);
      align-items: flex-start;
      gap: 2rem;
      height: max-content;
      .nav-links_items{
        .nav-link_btn{
          color:$white;
          font-size: 20px;
          span{
            color:$white;
          }
          &:hover{
            span{
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}



