






@font-face {
  font-family: 'SF-Pro_Display-Regular';
  src: url('../fonts/SfProDisplay/SFPRODISPLAYREGULAR.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
