





@mixin fadeInAnimation($disatance: 100px){
  translate: 0 $disatance;
  opacity: 0;
  visibility: hidden;
  animation: fade-in .2s ease-in-out forwards ;
}
@mixin fadeOutAnimation(){
  translate: 0 0;
  opacity: 1;
  visibility: visible;
  animation: fade-out .2s ease-in-out forwards ;
}
@mixin showAnimation(){
  animation: show .5s ease-in-out;
}
@mixin skeletonLoadingAnimation(){
  border-radius: 8px;
  animation: skeleton-loading 1s linear infinite alternate;
}

@mixin blurAnimation(){
  animation: blurContent .2s ease-in-out forwards;
}

@mixin rotateAnimation(){
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  from{
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes show {
  from{
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes ripple {
  to {
    scale: 4;
    opacity: 0;
  }
}


@keyframes fade-in {
  to{
    translate: 0 0;
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fade-out {
  to{
    translate: 0 100px;
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes error {
  0%{ translate: 1% 0}
  5%{ translate: -1% 0}
  10%{ translate: 1% 0}
  15%{ translate: -1% 0}
  20%{ translate: 1% 0}
  25%{ translate: -1% 0}
  30%{ translate:  0 0}
  //100%{ border: 2px solid transparent;}
}
@keyframes skeleton-loading {
  0%{
    background-color: rgba(170, 172, 174, 0.1);
  }
  100%{
    background-color: rgba(170, 172, 174, 0.2);
  }
}
@keyframes blurContent {
  to{
    backdrop-filter: blur(5px) brightness(0.5);
  }
}
