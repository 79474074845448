


$dark_blue-300: rgb(3,24,29);
$dark_blue-500:#172436;


$white: #FFFFFF;
$white-10: #fafafa;
$white-100: #f5f5f5;
$white-500: #ebebeb;
$white-600: #d7d2d2;
$white-700: #bcbcbc;

$black-500: #0B0B0B;
$black-600: #070707;

$blue-05: #f7f9ff;
$blue-06: #eaf0fb;
$blue-10: #B9D2FF;
$blue-100: rgb(221 236 255);
$blue-400: rgb(38, 128, 235);
$pink-100: rgb(247 221 255);
$pink-400: rgb(173 98 186);

$purple:#3b2172;
$purple-10:rgb(59 33 114 / 10%);

$green: #4cd137;
$green-10: #4cd1372b;

$pink-600: #b023b2;




$success-05: #5ac0e51f;
$success-100: rgba(90, 192, 229, 0.2);




$inactive: #bcbcbc;
$secondary-inactive: #f4f6ff;

$active:#B9D2FF;
$secondary-active:#d1dbff;
//sizes

$header-max-h: 70px;
$header-min-h: 60px;

$sub-header-h: 60px;







$fm_size_desk-32: 2vw;
$fm_size_desk-24: 1.75vw;
$fm_size_desk-20: 1.55vw;
$fm_size_desk-16: 1.1vw;
$fm_size_desk-14: .9vw;

$card-padding: 1rem;


$padding-0-30: 0 30px;
$padding-0-150: 0 150px;

$mx-content-width: 1200px;




$windowColor: #dfe9fc;
$main-border: 1px solid #bcbcbc;
$border-radius-4px: 4px;
$main-box-shadow: 0 0 6px 0 #4e4e4ed6;

//sizes
$mx-exp-content-width: 1360px;

//colors
$panel-light-color: #eaf0fb;



//----------------------------------------//
//--fonts--



$main-font:'Roboto';
$secondary-font:'SF-Pro_Display-Regular';


//---colors
$box-bg-main:#eaf0fb;
$box-bg-secondary:#B9D2FF;
$section-bg:#F9F9F9;

$scroll-bar-color: #ebebeb;
$error: #FF0000;
$success-color: #00b300;
$disabled-color:#9DA2A8;
$disabled-color-light: rgba(170, 172, 174, 0.44);
$main-color: #000000;
$secondary-color:#636769;



//$primary: #5AC0E5;
$primary: #027EAC;
$primary-cold: #D3EDFF;

$orange:#C25D00;
$orange-cold:#FFE9D6;

$pink:#7900C2;
$pink-cold:#EFE2FF;

$green:#078B04;
$green-cold:#E2F8DB;

$gray:#474747;
$gray-alter:#5c5c5c;
$gray-cold:#D6D6D6;

$highlight: #eaf0fb;

$main-color-03: rgb(64, 64, 64);//--?
$main-color-05: #00000066;//--?
$main-color-500: #767676;//--?


//--space
$main-space: 20px;
$inbox-space: 24px;
$item-space-between: 8px;

